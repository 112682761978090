import AuthBase from "../AuthBase";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import {Prompt, useHistory, useParams} from "react-router-dom";
import {
    Avatar,
    Grid,
    Stack,
} from "@mui/material";
import ConfigContext from "../../context/ConfigContext";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import userTemplate from "./UserTemplate";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import DinoDialog from "../../components/Dialog";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Loading from "../../components/Loading";
import General from "./components/General";
import ProfilePicture from "./components/ProfilePicture";
import Services from "./components/Services";
import {getImageUrl} from "../../utils/UrlUtils";
import Leaves from "./components/Leaves";
import {mergeDataIfNotNull} from "../../utils/misc";
import {useSnackbar} from "notistack";
import DeleteColleagueData from "./components/DeleteColleagueData";
import WorkHours from "./components/WorkHours";
import {hasPermission} from "../../utils/permissionUtils";
import AuthContext from "../../context/AuthContext";


const ColleagueDetail = () => {
    const {baseUrl} = useContext(ConfigContext)
    const [colleague, setColleague] = useState(userTemplate)
    const [editedColleague, setEditedColleague] = useState(userTemplate)
    const [colleagueDeleteDialog, setColleagueDeleteDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const generalRef = useRef(null)
    const profilePictureRef = useRef(null)
    const deleteRef = useRef(null)
    const {enqueueSnackbar} = useSnackbar()
    const params = useParams()
    const history = useHistory()
    const {user} = useContext(AuthContext)

    const {
        getData: fetchColleague,
        updateDataFormData: updateColleague,
        deleteData: deleteColleague,
    } = useCallDataApi('colleague')

    const hasUnsavedChanges = JSON.stringify(editedColleague) !== JSON.stringify(colleague)

    const savePageData = () => {
        const refData = [
            generalRef.current.getData(),
            profilePictureRef.current.getData(),
        ]
        const invalidRefs = refData.filter(ref => ref === null)
        if (invalidRefs.length === 0) {
            const allData = mergeDataIfNotNull(refData);
            const isFile = allData?.profile_picture instanceof File;
            if (!isFile) delete allData['profile_picture']
            setLoading(true)
            updateColleague(params?.id, allData).then(s => {
                setColleague(s)
                setEditedColleague(s)
                enqueueSnackbar('Mentve!', {variant: 'success'})
            }).finally(() => setLoading(false))
        } else {
            enqueueSnackbar('Kérlek javísd a hibákat!', {variant: 'error'})
        }
    }


    useEffect(() => {
        setLoading(true)
        Promise.all([
            fetchColleague(params?.id),
            fetchColleague(`get_role_for_colleague/?colleague=${params?.id}`),
        ])
            .then(([c, r]) => {
                setColleague(c)
                setEditedColleague(c)
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault()
                event.returnValue = "" // Required for modern browsers
            }
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            endIcon: <SaveRoundedIcon/>,
            disabled: !hasUnsavedChanges,
            variant: 'contained',
            onClick: savePageData,
        }
    }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            variant: 'contained',
            size: 'small',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    }]

    // const changeRole = (event) => {
    //     if (colleagueRole === 'owner') return
    //     const newValue = event.target.checked ? 'manager' : 'employee'
    //     postColleague('change_role/', {colleague: colleague.id, role: newValue}).then(r => setColleagueRole(r.role))
    // }

    return <AuthBase label={colleague?.name} buttons={buttons} filterButtonsMobile={buttonsMobile}>
        <div className="main">
            <Button variant='text' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.push(`/colleagues`)}>vissza</Button>
            <Grid container spacing={2}>

                {/*Headline*/}
                <Grid item xs={12}>
                    <div className="white-card">
                        <Stack direction='row' style={{justifyContent: 'space-between'}}>
                            <Stack direction='row' spacing={2}>
                                <Avatar src={getImageUrl(colleague?.profile_picture, baseUrl)}
                                        sx={{width: 90, height: 90}}/>
                                <Stack spacing={1} style={{alignSelf: 'center'}}>
                                    <Typography variant='headline6'>{colleague?.name}</Typography>
                                    <Typography variant='body1' color='#536B74'>{colleague?.title}</Typography>
                                </Stack>
                            </Stack>
                            <IconButton
                                disabled={hasPermission(user, "colleague", "remove", colleague)}
                                sx={{
                                    width: 50,
                                    height: 50,
                                    color: 'primary.main',
                                    alignSelf: 'center'
                                }}
                                onClick={() => setColleagueDeleteDialog(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </Stack>
                    </div>
                </Grid>

                {/*General data*/}
                <Grid item xs={12} md={6}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Általános adatok</Typography>
                        <Typography variant='body2' color='#536B74'>
                            Add meg a kolléga adatait!
                        </Typography>
                        <General
                            reference={generalRef}
                            data={editedColleague}
                            setData={setEditedColleague}
                            canEditRole={hasPermission(user, "colleague", "changeRole", colleague)}
                        />
                    </div>
                </Grid>

                {/*Profile picture*/}
                <Grid item xs={12} md={6}>
                    <div className="white-card">
                        <Typography variant='subtitle1'>Kolléga profilképe</Typography>
                        <Typography variant='body2' color='#536B74'>
                            Töltsd fel a kolléga profilképét! (Ez a kép fog megjelenni a foglalási felületen is!)
                        </Typography>
                        <ProfilePicture
                            reference={profilePictureRef}
                            data={editedColleague}
                            setData={setEditedColleague}
                        />
                    </div>
                </Grid>

                {/*Services*/}
                <Grid item xs={12} md={12}>
                    <Services/>
                </Grid>

                {/*Work hours*/}
                <Grid item xs={12} md={12}>
                    <WorkHours userId={params?.id}/>
                </Grid>

                {/*Leaves*/}
                <Grid item xs={12} md={12}>
                    <Leaves colleagueId={params?.id}/>
                </Grid>
            </Grid>
        </div>

        <DinoDialog open={colleagueDeleteDialog}
                    title='Kolléga törlése'
                    subtitle='Minden hozzá tartozó adat el fog veszni, foglalások, árak, stb...!'
                    handleClose={() => setColleagueDeleteDialog(false)}
                    actions={<Stack direction='row' spacing={1}>
                        <Button
                            variant='outlined'
                            onClick={() => setColleagueDeleteDialog(false)}>
                            mégsem
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => {
                                const data = deleteRef.current.getData()
                                if (data) {
                                    setColleagueDeleteDialog(false)
                                    deleteColleague(params.id).then(() => history.push('/colleagues'))
                                }
                            }} endIcon={<DeleteRoundedIcon/>}>
                            Törlés
                        </Button>
                    </Stack>}>
            <DeleteColleagueData reference={deleteRef}/>
        </DinoDialog>

        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />

        <Loading isLoading={loading}/>
    </AuthBase>
}

export default ColleagueDetail