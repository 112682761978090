import AuthBase from "../AuthBase";
import Typography from "@mui/material/Typography";
import {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import businessTemplate from "./BusinessTemplate";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Loading from "../../components/Loading";
import _ from "lodash";
import {useSnackbar} from "notistack";
import Contact from "./components/Contact";
import Colleagues from "./components/Colleagues";
import Services from "./components/Services";
import Home from "./components/Home";
import Colors from "./components/Colors";
import Company from "./components/Company";
import Redirect from "./components/Redirect";
import Mode from "./components/Mode";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {Prompt} from "react-router-dom";
import {mergeDataIfNotNull} from "../../utils/misc";
import Payment from "./components/Payment";
import Invoice from "./components/Invoice";
import AuthConfigContext from "../../context/AuthConfigContext";

const Web = () => {
    const {billingAvailable} = useContext(AuthConfigContext)
    const [business, setBusiness] = useState(businessTemplate)
    const [editedBusiness, setEditedBusiness] = useState(businessTemplate)
    const [loading, setLoading] = useState(false)

    const {enqueueSnackbar} = useSnackbar()

    const {
        getData: fetchBusiness,
        updateDataFormData: updateBusinessFormData,
    } = useCallDataApi('business')

    const contactRef = useRef(null)
    const colleaguesRef = useRef(null)
    const categoryRef = useRef(null)
    const heroRef = useRef(null)
    const colorRef = useRef(null)
    const companyRef = useRef(null)
    const redirectRef = useRef(null)
    const paymentRef = useRef(null)
    const invoiceRef = useRef(null)
    const modeRef = useRef(null)

    useEffect(() => {
        setLoading(true)
        fetchBusiness('get_business')
            .then(b => {
                setBusiness(b)
                setEditedBusiness(_.cloneDeep(b))
            })
            .finally(() => setLoading(false))
    }, [])

    const savePageData = () => {
        const refData = [
            redirectRef.current.getData(),
            heroRef.current.getData(),
            categoryRef.current.getData(),
            colleaguesRef.current.getData(),
            contactRef.current.getData(),
            colorRef.current.getData(),
            companyRef.current.getData(),
            paymentRef.current.getData(),
            invoiceRef.current.getData(),
            modeRef.current.getData(),
        ]
        const editedData = mergeDataIfNotNull(refData);
        if (editedData !== null) {
            const isFile = editedData?.public_logo instanceof File;
            if (!isFile) delete editedData['public_logo'] // If the image is not updated, it is a string. The backend will throw an error for the request
            setLoading(true)
            updateBusinessFormData(business?.id, editedData).then(b => {
                setBusiness(b)
                setEditedBusiness(_.cloneDeep(b))
                enqueueSnackbar('Mentve!', {variant: 'success'})
            }).finally(() => setLoading(false))
        } else {
            enqueueSnackbar('Kérlek javísd a hibákat!', {variant: 'error'})
        }
    }

    const openWebsite = () => {
        window.open(`https://${business?.subdomain}.dinobooking.hu/`)
    }

    const hasUnsavedChanges = JSON.stringify(editedBusiness) !== JSON.stringify(business);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = ""; // Required for modern browsers
            }
        };

        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [hasUnsavedChanges]);

    const buttons = [{
        name: 'Változtatások mentése',
        props: {
            variant: 'contained',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    },
        {
            name: 'Weboldal megtekintése',
            props: {
                variant: 'contained',
                endIcon: <RemoveRedEyeRoundedIcon/>,
                onClick: openWebsite
            }
        }]

    const buttonsMobile = [{
        name: 'Mentés',
        props: {
            variant: 'contained',
            size: 'small',
            disabled: !hasUnsavedChanges,
            endIcon: <SaveRoundedIcon/>,
            onClick: savePageData
        }
    }, {
        name: 'Megnéz',
        props: {
            variant: 'outlined',
            size: 'small',
            endIcon: <RemoveRedEyeRoundedIcon/>,
            onClick: openWebsite
        }
    }]

    const onlyBooking = editedBusiness?.usage_type === 'only_booking'
    const webshopAndWebsite = editedBusiness?.usage_type === 'website_webshop'

    return <AuthBase label='Weboldal' buttons={buttons} filterButtonsMobile={buttonsMobile}>

        <div className="main">
            <div className="white-card">
                <Typography variant='subtitle1'>Weboldal típusa</Typography>
                <Typography color='#536B74' variant='body2'>
                    Válaszd ki a vállalkozásod számára megfelelő weboldal típust
                </Typography>
                <Mode reference={modeRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>

            <div className="white-card" style={{display: !onlyBooking ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Visszairányítási weboldal címe</Typography>
                <Typography color='#536B74' variant='body2'>
                    Állítsd be weboldalad címét, így ha foglalást ad le valaki, erre a címre irányítsa vissza a
                    Dinobooking.
                    Fontos hogy az egész url ki legyen írva, pl.: https://weboldalam.hu/
                </Typography>
                <Redirect reference={redirectRef} data={editedBusiness} setData={setEditedBusiness} required={onlyBooking}/>
            </div>

            <div className="white-card" style={{display: !webshopAndWebsite ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Webshop fizetési beállítások</Typography>
                <Typography color='#536B74' variant='body2'>
                    Itt tudod beállítni, hogy ügyfeleid hogyan tudják kifizetni a megrendeléseikhez
                </Typography>
                <Payment reference={paymentRef} data={editedBusiness} setData={setEditedBusiness} paymentAuthorized={business?.payment_authorized}/>
            </div>

            <div className="white-card" style={{display: !webshopAndWebsite ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Webshop számlázási beállítások</Typography>
                <Typography color='#536B74' variant='body2'>
                    Itt tudod beállítni, hogy ügyfeleid hogyan kapjanak számlát a megrendeléseikhez
                </Typography>
                <Invoice reference={invoiceRef} data={editedBusiness} setData={setEditedBusiness} canUseAutoInvoice={billingAvailable() && business?.payment_authorized}/>
            </div>

            <div className="white-card">
                <Typography variant='subtitle1'>Cég adatai</Typography>
                <Typography color='#536B74' variant='body2'>
                    Töltsd ki vállalkozásod adatait amiket meg szeretnél jeleníteni weboldaladon!
                </Typography>
                <Company reference={companyRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>

            <div className="white-card">
                <Typography variant='subtitle1'>Alapszín kiválasztása</Typography>
                <Typography color='#536B74' variant='body2'>
                    Válaszd ki a vállalkozásodhoz leginkább illő színt!
                </Typography>
                <Colors reference={colorRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>

            <div className="white-card" style={{display: onlyBooking ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Kezdőlap</Typography>
                <Typography color='#536B74' variant='body2'>
                    Címnek egyszerű rövid pár szóból álló lényegretörő értékajánlatot érdemes
                    megadni, amelyet az alcímben jobban kifejthetsz. (pl.: Környezettudatos kiszállítás
                    Budapesten)
                </Typography>
                <Home reference={heroRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>

            <div className="white-card" style={{display: onlyBooking ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Szolgáltatások</Typography>
                <Typography color='#536B74' variant='body2'>
                    Röviden foglald össze milyen szolgáltatásokat nyújt vállalkozásod ügyfeleid számára!
                </Typography>
                <Services reference={categoryRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>

            <div className="white-card" style={{display: onlyBooking ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Kollégáink</Typography>
                <Typography color='#536B74' variant='body2'>
                    Röviden foglald össze kollégáid miben tudnak kiemelkedőt nyújtani a konkurenciához képet!
                </Typography>
                <Colleagues reference={colleaguesRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>

            <div className="white-card" style={{display: onlyBooking ? 'none' : 'block'}}>
                <Typography variant='subtitle1'>Kapcsolat</Typography>
                <Typography color='#536B74' variant='body2'>
                    Írj egy rövid felhívó üzenetet ügyfeled számára amivel felkelted érdeklődésüket!
                </Typography>
                <Contact reference={contactRef} data={editedBusiness} setData={setEditedBusiness}/>
            </div>
        </div>

        <Loading isLoading={loading}/>

        <Prompt
            when={hasUnsavedChanges}
            message="Elmentetlen változtatásaid vannak, biztosan elmész erről az oldalról?"
        />
    </AuthBase>
}

export default Web