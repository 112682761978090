import {Stack, Tooltip} from "@mui/material";
import {useState} from "react";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomSwitch from "../../../components/formValidation/CustomSwitch";

const Payment = ({reference, data, setData, paymentAuthorized}) => {

    const [errors, setErrors] = useState({
        webshop_cash_payment: false,
        webshop_card_payment: false,
    });

    const validators = {
        webshop_cash_payment: [],
        webshop_card_payment: [],
    }

    const validateAtLeastOneSelected = obj => {
        const { webshop_cash_payment, webshop_card_payment, is_pay_advance } = obj
        const isAtLeastOneSelected = webshop_cash_payment || webshop_card_payment || is_pay_advance
        return {valid: isAtLeastOneSelected, error: 'Legalább egy fizetési lehetőséget ki kell választani!'}
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference} customValidators={[validateAtLeastOneSelected]}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='webshop_cash_payment'
                labelOn='Helyszíni fizetés bekapcsolva'
                labelOff='Helyszíni fizetés kikapcsolva'
            />
            {paymentAuthorized ?
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='webshop_card_payment'
                    labelOn='Online fizetés bekapcsolva'
                    labelOff='Online fizetés kikapcsolva'
                />
                :
                <Tooltip title='Állítsd be az online fizetést a beállításokban ehhez az opcióhoz!'>
                    <span>
                        <CustomSwitch
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            variant='standard'
                            disabled
                            labelOn='Online fizetés bekapcsolva'
                            labelOff='Online fizetés kikapcsolva'
                        />
                    </span>
                </Tooltip>
            }
        </Stack>
    </ErrorHandlingForm>
}

export default Payment