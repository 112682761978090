import Typography from "@mui/material/Typography";
import {Avatar, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import thousandSeparator from "../../../../utils/numberUtils";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DinoDialog from "../../../../components/Dialog";
import PriceData from "./PriceData";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {useParams} from "react-router-dom";
import {useContext, useEffect, useRef, useState} from "react";
import ConfigContext from "../../../../context/ConfigContext";
import useCallDataApi from "../../../../hooks/data";
import Loading from "../../../../components/Loading";


const Colleagues = () => {
    const {baseUrl} = useContext(ConfigContext)

    const [colleagues, setColleagues] = useState([])
    const [prices, setPrices] = useState([])
    const [priceDialog, setPriceDialog] = useState(false)
    const [priceDeleteDialog, setPriceDeleteDialog] = useState(false)
    const [priceEditDialog, setPriceEditDialog] = useState(false)
    
    const [loading, setLoading] = useState(true)

    const [current, setCurrent] = useState(-1)

    const params = useParams()
    const priceEditForm = useRef(null)
    const newPriceForm = useRef(null)
    
    const {
        getData: fetchPrice,
        createData: createPrice,
        deleteData: deletePrice,
        updateData: updatePrice
    } = useCallDataApi('price')
    const {getData: fetchColleague} = useCallDataApi('colleague')


    useEffect(() => {
        setLoading(true);
        Promise.all([
            fetchColleague('get_for_business/'),
            fetchPrice(`get_for_service/?service=${params?.id}`)
        ])
            .then(([c, p]) => {
                setColleagues(c)
                setPrices(p);
            })
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findColleague = (id) => colleagues.find(c => c.id === id)

    const findPrice = (id) => prices.find(p => p.id === id)
    
    const editPrice = () => {
        const data = priceEditForm.current.getData()
        if (data) {
            setLoading(true)
            setPriceEditDialog(false)
            updatePrice(current, {...data, service: params.id})
                .then(p => setPrices([...prices.filter(pr => pr.id !== p.id), p]))
                .finally(() => setLoading(false))
        }
    }

    const newPrice = () => {
        const data = newPriceForm.current.getData()
        if (data) {
            setLoading(true)
            setPriceDialog(false)
            createPrice({...data, service: params.id})
                .then(p => setPrices([...prices, p]))
                .finally(() => setLoading(false))
        }
    }

    const removePrice = () => {
        deletePrice(current).then(() => setPrices(prices.filter(p => p.id !== current)))
    }

    return <div className="white-card">
        <Typography variant='subtitle1'>Kollégák</Typography>
        <Typography variant='body2' color='#536B74'>
            Vedd fel azokat a kollégákat akik ezt a szolgáltatást nyújtják!
        </Typography>
        <Stack spacing={2} marginTop={3} marginBottom={3}>
            {prices.length === 0 &&
                <Box sx={{border: '1px solid', borderColor: '#F1F4F5', borderRadius: '16px'}}>
                    <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                        <Typography variant='subtitle2' color='#6F8E9A'>Egyetlen kolléga sem végzi ezt a szolgáltatást!</Typography>
                        <Typography variant='body2' color='#6F8E9A'>
                            Adj hozzá új kollégát az alábbi gombra kattintva! 👇
                        </Typography>
                    </Stack>
                </Box>}
            {prices.map((p, i) => <Stack direction='row' key={`price_${i}`} justifyContent="space-between">
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Avatar src={baseUrl + findColleague(p.colleague?.id)?.profile_picture}
                            sx={{width: '48px', height: '48px'}}/>
                    <Stack>
                        <Typography variant='subtitle2'>{findColleague(p.colleague?.id)?.name}</Typography>
                        <Typography variant='caption' color='#536B74'>
                            {findColleague(p.colleague?.id)?.title}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='caption'
                                color='#2F5C43'>{thousandSeparator(p.price)} Ft</Typography>
                    <IconButton onClick={() => {
                        setCurrent(p.id)
                        setPriceEditDialog(true)
                    }} sx={{
                        border: '2px solid',
                        borderColor: 'primary.400',
                        background: '#fff',
                        color: 'primary.400'
                    }}><ModeEditOutlineRoundedIcon/></IconButton>
                    <IconButton onClick={() => {
                        setCurrent(p.id)
                        setPriceDeleteDialog(true)
                    }} sx={{
                        border: '2px solid',
                        borderColor: 'primary.400',
                        background: '#fff',
                        color: 'primary.400'
                    }}><DeleteRoundedIcon/></IconButton>
                </Stack>
            </Stack>)}
        </Stack>
        <Button variant='contained' endIcon={<AddCircleIcon/>} onClick={() => setPriceDialog(true)}>
            Új ár hozzáadása
        </Button>


        <DinoDialog open={priceDialog}
                    title='Új ár hozzáadása'
                    subtitle='Add meg, hogy az adott kollága mennyiért végzi ezt a szolgáltatást!'
                    handleClose={() => setPriceDialog(false)}>
            <PriceData reference={newPriceForm} prices={prices}/>
            <Stack direction='row' alignItems='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => newPrice()} endIcon={<AddCircleIcon/>}>
                    Ár hozzáadása
                </Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceDeleteDialog}
                    title='Ár törlése'
                    subtitle='Ha kitörlöd az árat a kliensek számára nem fog megjelenni a foglalási folyamat során!'
                    handleClose={() => setPriceDeleteDialog(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceDeleteDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setPriceDeleteDialog(false)
                    removePrice()
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={priceEditDialog}
                    title='Ár szerkesztése'
                    subtitle='Add meg az új árat!'
                    handleClose={() => setPriceEditDialog(false)}>
            <PriceData reference={priceEditForm} obj={findPrice(current)} prices={prices}/>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setPriceEditDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    editPrice()
                }} endIcon={<SaveRoundedIcon/>}>Változtatások mentése</Button>
            </Stack>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Colleagues