import {MenuItem, Stack, Tooltip} from "@mui/material";
import {useState} from "react";
import ErrorHandlingForm from "../../../../components/formValidation/ErrorHandlingForm";
import CustomCheckbox from "../../../../components/formValidation/CustomCheckbox";
import CustomSelect from "../../../../components/formValidation/CustomSelect";
import CustomSwitch from "../../../../components/formValidation/CustomSwitch";
import Typography from "@mui/material/Typography";

const Payment = ({reference, data, setData, paymentAuthorized}) => {

    const [errors, setErrors] = useState({
        is_pay_cash_or_card: false,
        is_pay_online: false,
        is_pay_advance: false,
        advance_amount: false,
    });

    const validators = {
        is_pay_cash_or_card: [],
        is_pay_online: [],
        is_pay_advance: [],
        advance_amount: [],
    }

    const validateAtLeastOneSelected = obj => {
        const { is_pay_cash_or_card, is_pay_online, is_pay_advance } = obj
        const isAtLeastOneSelected = is_pay_cash_or_card || is_pay_online || is_pay_advance
        return {valid: isAtLeastOneSelected, error: 'Legalább egy fizetési lehetőséget ki kell választani!'}
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors} validators={validators} reference={reference} customValidators={[validateAtLeastOneSelected]}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <CustomSwitch
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='is_pay_cash_or_card'
                labelOn='Helyszíni fizetés bekapcsolva'
                labelOff='Helyszíni fizetés kikapcsolva'
            />
            {paymentAuthorized ?
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='is_pay_online'
                    labelOn='Online fizetés bekapcsolva'
                    labelOff='Online fizetés kikapcsolva'
                />
                :
                <Tooltip title='Állítsd be az online fizetést a beállításokban ehhez az opcióhoz!'>
                    <span>
                        <CustomSwitch
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            variant='standard'
                            disabled
                            labelOn='Online fizetés bekapcsolva'
                            labelOff='Online fizetés kikapcsolva'
                        />
                    </span>
                </Tooltip>
            }
            {paymentAuthorized ?
                <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='is_pay_advance'
                    labelOn='Előleg fizetés bekapcsolva'
                    labelOff='Előleg fizetés kikapcsolva'
                />
                :
                <Tooltip title='Állítsd be az online fizetést a beállításokban ehhez az opcióhoz!'>
                    <span>
                        <CustomSwitch
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            variant='standard'
                            disabled
                            labelOn='Előleg fizetés bekapcsolva'
                            labelOff='Előleg fizetés kikapcsolava'
                        />
                    </span>
                </Tooltip>
            }
            {(paymentAuthorized && data?.is_pay_advance)  &&
                <>
                    <Typography variant='caption' color='primary.main'>Előleg mértéke</Typography>
                    <CustomSelect
                        data={data}
                        setData={setData}
                        errors={errors}
                        setErrors={setErrors}
                        validators={validators}
                        variant='standard'
                        name='advance_amount'
                    >
                        <MenuItem value={10}>10%</MenuItem>
                        <MenuItem value={25}>25%</MenuItem>
                        <MenuItem value={50}>50%</MenuItem>
                    </CustomSelect>
                </>
            }
        </Stack>
    </ErrorHandlingForm>
}

export default Payment