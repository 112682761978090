const ROLES = {
    owner: {
        colleague: {
            view: true,
            create: true,
            update: true,
            remove: (user, colleague) => user.id !== colleague.id,
            changeRole: (user, colleague) => user.user_id !== colleague.id,
        },
        settings: {

        }
    },
    manager: {
        colleague: {
            view: true,
            create: true,
            update: (user, colleague) => colleague.role === 'employee',
            remove: (user, colleague) => user.id !== colleague.id,
            changeRole: (user, colleague) => user.user_id !== colleague.id,
        },
    },
    employee: {
        colleague: {
            view: (user, colleague) => user.user_id === colleague.id,
            create: false,
            update: (user, colleague) => user.id === colleague.id,
            remove: false,
            changeRole: false
        },
    },
};

const hasPermission = (user, resource, action, data) => {
    const role = user.role
    if (!role) return false

    const permission = ROLES[role]?.[resource]?.[action]
    if (permission == null) return false;

    if (typeof permission === "boolean") return permission;
    return data != null && permission(user, data);
}

export {hasPermission}
