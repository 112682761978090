import {matchIsValidTel} from "mui-tel-input";


const validateChars = (value) => {
    const res = !!/^[a-z]+$/.test(value)
    return {
        valid: res,
        error: 'Csak ékezet nélküli kisbetűs karaktereket tartalmazhat (a-z)'
    }
}

const validateMinLength = (minLength, value) => {
    const res = value.length > minLength
    return {
        valid: res,
        error: `Legalább ${minLength + 1} karakter hosszú kell hogy legyen`
    }
}

const validateMaxLength = (maxLength, value) => {
    const res = value?.length < maxLength
    return {
        valid: res,
        error: `Legfeljebb ${maxLength} karakter hosszú lehet`
    }
}

const validateEmail = (value) => {
    if (!value) return {
        valid: true,
        error: 'Nem kötelezö mező'
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: 'Nem érvényes e-mail cím'
    }
}

const validateVatNumber = (value) => {
    if (!value) return {
        valid: true,
        error: 'Nem kötelezö mező'
    }
    const emailRegex = /^\d{8}-\d-\d{2}$/;
    const res = emailRegex.test(value);
    return {
        valid: res,
        error: 'Nem érvényes adószám, (xxxxxxxx-y-zz)'
    }
}

const validateTimeValue = (value) => {
    if (!value) {
        return {
            valid: true,
            error: 'Nem kötelező mező'
        };
    }

    // If the value is a Date object, extract the time portion
    if (value instanceof Date) {
        if (isNaN(value)) {
            return {
                valid: false,
                error: 'Érvénytelen Idő'
            };
        }
        // Extract time in HH:mm format
        value = value.toLocaleTimeString('hu-HU', { hour: '2-digit', minute: '2-digit', hour12: false });
    }

    // Validate string value against HH:mm format
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return {
        valid: timeRegex.test(value),
        error: 'A megadott idő nem érvényes, helyes formátum: HH:mm (24 órás formátum)'
    };
};

const validateNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: res || !value,
        error: 'Csak számokat tartalmazhat'
    }
}

const validateNonNegativeNumber = (value) => {
    const numericRegex = /^-?[0-9]+$/;
    const res = numericRegex.test(value);
    return {
        valid: res && parseInt(value) >= 0,
        error: 'Nem lehet negatív szám'
    }
}

const validateMinValue = (value, min) => {
    return {
        valid: parseInt(value) >= min,
        error: `Nagyobb kell, hogy legyen mint: ${min}`
    }
}

const validateMaxValue = (value, max) => {
    return {
        valid: parseInt(value) <= max,
        error: `Kisebb kell, hogy legyen mint: ${max}`
    }
}

const validateDividableBy = (value, num) => {
    return {
        valid: parseInt(value) % num === 0,
        error: `Oszthatónek kell lennie ezzel: ${num}`
    }
}

const validateDivisibleBy = (value, number) => {
    return {
        valid: parseInt(value) % number === 0 || !value,
        error: `Nem osztható ezzel: ${5}`
    }
}

const validateRequired = (value) => {
    return {
        valid: Boolean(value) || value === 0,
        error: 'Nem maradhat üresen'
    }
}

const validateOneOf = (value, list) => {
    if (!value) return {
        valid: true,
        error: false,
    }
    const isValid = list.includes(value);
    return {
        valid: isValid,
        error: isValid ? false : 'Nem felel meg a választható opciók egyikének sem'
    }
}

const validateEqualsPassword = (equal, value) => {
    return {
        valid: equal === value,
        error: 'Nem egyezik meg a két jelszó'
    }
}

const validateUrl = (value) => {
    if (!value) return {
        valid: true,
        error: false
    }
    const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/\S*)?$/;
    return {
        valid: urlPattern.test(value),
        error: 'Érvénytelen URL'
    };
};


const validateDate = (value) => {
    const isValidDateObject = (date) => date instanceof Date && !isNaN(date);
    // Helper function to check if a string is in YYYY-MM-DD format and represents a valid date
    const isValidDateString = (dateString) => {
        const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
        if (!dateRegex.test(dateString)) return false;

        const [year, month, day] = dateString.split('-').map(Number);
        const dateObj = new Date(year, month - 1, day);
        return (
            dateObj.getFullYear() === year &&
            dateObj.getMonth() === month - 1 &&
            dateObj.getDate() === day
        );
    };

    const isValid = isValidDateObject(value) || (typeof value === 'string' && isValidDateString(value));
    return {
        valid: isValid,
        error: 'A megadott dátum nem érvényes. Helyes formátum: ÉÉÉÉ-HH-NN (pl.: 2024. 12. 16.).'
    };
}


const validatePassword = (value) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;

    const isValid = hasNumber.test(value) && hasSpecialChar.test(value)
    return {
        valid: isValid,
        error: 'Tartalmaznia kell egy speciális karaktert (!@#$%^&*(),.?":{}|<>) és egy számot'
    }
}

const validateMaxFileSize = (file, size) => {
    const isFile = file instanceof File
    if (!isFile || file === null) {
        return { valid: true, error: false }
    }
    return {
        valid: file?.size <= size * 1000 * 1000 || !file || typeof file === 'string',
        error: `A file nem lehet nagyobb mint ${size} MB`
    }
}

const validatePhoneNumber = (value) => {
    if (!value) return {
        valid: true,
        error: false
    }
    return {
        valid: matchIsValidTel(value),
        error: 'Nem valid telefonszám'
    }
}

export {
    validateChars,
    validateMinLength,
    validateEmail,
    validateRequired,
    validateEqualsPassword,
    validatePassword,
    validateMaxLength,
    validateNumber,
    validateVatNumber,
    validateDate,
    validateNonNegativeNumber,
    validateMaxFileSize,
    validateDivisibleBy,
    validateTimeValue,
    validateUrl,
    validateMinValue,
    validateDividableBy,
    validateMaxValue,
    validateOneOf,
    validatePhoneNumber
}