import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AuthContext from "../../../../context/AuthContext";
import {useEffect, useState, useContext, useRef} from "react";
import useCallDataApi from "../../../../hooks/data";
import {useParams} from "react-router-dom";
import DinoDialog from "../../../../components/Dialog";
import QuestionData from "./QuestionData";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import Loading from "../../../../components/Loading";


const Questions = () => {
    const [questions, setQuestions] = useState([])
    const [loading, setLoading] = useState(false)
    const [questionDialog, setQuestionDialog] = useState(false)
    const [questionEditDialog, setQuestionEditDialog] = useState(false)
    const [questionDeleteDialog, setQuestionDeleteDialog] = useState(false)
    const [current, setCurrent] = useState(-1)
    const {subscriptionPlan} = useContext(AuthContext)
    const questionForm = useRef(null)
    const questionEditForm = useRef(null)
    const params = useParams()

    const {
        getData: fetchQuestion,
        createData: createQuestion,
        deleteData: deleteQuestion,
        updateData: updateQuestion
    } = useCallDataApi('service-question')

    useEffect(() => {
        setLoading(true)
        fetchQuestion(`get_for_service/?service=${params?.id}`)
            .then(q => setQuestions(q))
            .finally(() => setLoading(false))
    }, [])

    const answerType = {
        '1': 'Szabad szöveges válasz',
        '2': 'Szám válasz',
        '3': 'Igen/nem',
    }

    const newQuestion = () => {
        const data = questionForm.current.getData()
        if (data) {
            setQuestionDialog(false)
            setLoading(true)
            createQuestion({...data, service: params.id})
                .then(q => {
                    setQuestions([...questions, q])
                })
                .finally(() => setLoading(false))
        }
    }

    const editQuestion = () => {
        const data = questionEditForm.current.getData()
        if (data) {
            setQuestionEditDialog(false)
            setLoading(true)
            updateQuestion(current, {
                ...data,
                service: params.id
            })
                .then(q => setQuestions([...questions.filter(k => k.id !== q.id), q]))
                .finally(() => setLoading(false))
        }
    }

    const removeQuestion = () => {
        setLoading(true)
        deleteQuestion(current)
            .then(() => setQuestions(questions.filter(q => q.id !== current)))
            .finally(() => setLoading(false))
    }

    return <div className="white-card">
        <Typography variant='subtitle1'>Szolgáltatás specifikus kérdések</Typography>
        <Typography variant='body2' color='#536B74'>Adj hozzá a foglalási folyamathoz további kérdéseket
            amit
            meg szeretnél kérdezni a klienseidtől</Typography>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            {questions.length === 0 &&
                <Box sx={{border: '1px solid', borderColor: '#F1F4F5', borderRadius: '16px'}}>
                    <Stack spacing={2} alignItems='center' justifyContent='center' sx={{padding: 2}}>
                        <Typography variant='subtitle2' color='#6F8E9A'>Nincsenek kérdések</Typography>
                        <Typography variant='body2' color='#6F8E9A'>
                            Adj hozzá új kérdést az alábbi gombra kattintva! 👇
                        </Typography>
                    </Stack>
                </Box>}

            {questions.map((q, i) => <Stack
                key={`q_${i}`}
                spacing={2}
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                sx={{flexWrap: 'wrap'}}>
                <Typography variant='body1' color='#536B74' sx={{ wordBreak: 'break-word', maxWidth: '100%' }}>{q.question}{q.required && '*'}</Typography>
                <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='caption' color='#2F5C43'>{answerType[q.answer_type]}</Typography>
                    <IconButton disabled={subscriptionPlan !== 'multiple'}
                                onClick={() => {
                                    setCurrent(q.id)
                                    setQuestionEditDialog(true)
                                }}
                                sx={{
                                    border: '2px solid',
                                    borderColor: 'primary.main',
                                    background: '#fff',
                                    color: 'primary.main'
                                }}><ModeEditOutlineRoundedIcon/></IconButton>
                    <IconButton disabled={subscriptionPlan !== 'multiple'}
                                onClick={() => {
                                    setCurrent(q.id)
                                    setQuestionDeleteDialog(true)
                                }}
                                sx={{
                                    border: '2px solid',
                                    borderColor: 'primary.main',
                                    background: '#fff',
                                    color: 'primary.main'
                                }}><DeleteRoundedIcon/></IconButton>
                </Stack>
            </Stack>)}
        </Stack>
        <Button
            disabled={subscriptionPlan !== 'multiple'} // TODO WTF?????
            variant='contained'
            onClick={() => setQuestionDialog(true)}
            endIcon={<AddCircleIcon/>}>
            Új kérdés hozzáadása
        </Button>


        <DinoDialog open={questionDialog}
                    title='Új kérdés hozzáadása'
                    subtitle='Add meg a kérdés adatait!'
                    handleClose={() => setQuestionDialog(false)}>
            <QuestionData reference={questionForm}/>
            <Stack direction='row' alignItems='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setQuestionDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => newQuestion()} endIcon={<AddCircleIcon/>}>Kérdés
                    hozzáadása</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={questionEditDialog}
                    title='Kérdés szerkezstése'
                    subtitle='Add meg a kérdés adatait!'
                    handleClose={() => setQuestionEditDialog(false)}>
            <QuestionData reference={questionEditForm} obj={questions.find(q => q.id === current)}/>
            <Stack direction='row' alignItems='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setQuestionEditDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => editQuestion()} endIcon={<SaveRoundedIcon/>}>Változtatások
                    mentése</Button>
            </Stack>
        </DinoDialog>

        <DinoDialog open={questionDeleteDialog}
                    title='Kérdés törlése'
                    subtitle='Ha kitörlöd a kérdést a kliensek számára nem fog megjelenni a foglalási folyamat során!'
                    handleClose={() => setQuestionDeleteDialog(false)}>
            <Stack direction='row' justifyContent='end' spacing={1} marginTop={3}>
                <Button variant='outlined' onClick={() => setQuestionDeleteDialog(false)}>Mégsem</Button>
                <Button variant='contained' onClick={() => {
                    setQuestionDeleteDialog(false)
                    removeQuestion()
                }} endIcon={<DeleteRoundedIcon/>}>Törlés</Button>
            </Stack>
        </DinoDialog>

        <Loading isLoading={loading}/>
    </div>
}

export default Questions