import {
    Divider,
    FormControlLabel,
    Radio,
    Stack, Tooltip
} from "@mui/material";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import ErrorHandlingForm from "../../../components/formValidation/ErrorHandlingForm";
import CustomRadioGroup from "../../../components/formValidation/CustomRadioGroup";
import CustomSwitch from "../../../components/formValidation/CustomSwitch";


const Invoice = ({reference, data, setData, canUseAutoInvoice}) => {
    console.log(data?.mandatory_billing_details)
    const [errors, setErrors] = useState({
        mandatory_billing_details: false,
        automatic_invoice: false,
    });

    const validators = {
        mandatory_billing_details: [],
        automatic_invoice: [],
    }

    return <ErrorHandlingForm data={data} setData={setData} errors={errors} setErrors={setErrors}
                              validators={validators} reference={reference}>
        <Stack spacing={1} marginTop={2} marginBottom={2}>
            <Typography variant='caption' color='primary.main'>
                Helyszíni fizetés esetén
            </Typography>
            <CustomRadioGroup
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                validators={validators}
                variant='standard'
                name='mandatory_billing_details'
            >
                <FormControlLabel
                    value='none'
                    control={<Radio/>}
                    label={<Typography variant='caption'>
                        Ne kérjen számlázási adatokat
                    </Typography>}/>
                <FormControlLabel
                    value='optional' control={<Radio/>}
                    label={<Typography variant='caption'>
                        Opcionálisan kitöltheti
                    </Typography>}/>
                <FormControlLabel
                    value='mandatory' control={<Radio/>}
                    label={<Typography variant='caption'>
                        Kötelezően ki kell tölteni
                    </Typography>}/>

            </CustomRadioGroup>

            <Divider/>

            <Typography variant='caption' color='primary.main'>
                sikeres online fizetés esetén
            </Typography>
            {!canUseAutoInvoice ?
                <Tooltip title="Állítsd be az online fizetést és számlázást a beállításokban ehhez az opcióhoz!">
                    <span>
                        <CustomSwitch
                            data={data}
                            setData={setData}
                            errors={errors}
                            setErrors={setErrors}
                            validators={validators}
                            variant='standard'
                            disabled
                            labelOn='Bekapcsolva'
                            labelOff='Kikapcsolva'
                        />
                    </span>
                </Tooltip>
                : <CustomSwitch
                    data={data}
                    setData={setData}
                    errors={errors}
                    setErrors={setErrors}
                    validators={validators}
                    variant='standard'
                    name='automatic_invoice'
                    labelOn='Bekapcsolva'
                    labelOff='Kikapcsolva'
                />}
        </Stack>
    </ErrorHandlingForm>
}

export default Invoice

